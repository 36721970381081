import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncCallback } from "../hooks";
import { Checkoutin, createMatch, getPlayers, getSession } from "../api";

export interface NewMatchDialogProps {
  defaultPlayers: string[];
  show: boolean;
  onClose: () => void;
}

interface NewMatchPlayer {
  handicap: number | null;
  name: string;
}

const NewMatchDialog: React.FC<NewMatchDialogProps> = ({
  defaultPlayers,
  show,
  onClose,
}) => {
  const navigate = useNavigate();
  const session = getSession()!;
  const [randomOrder, setRandomOrder] = useState(true);
  const [startingScore, setStartingScore] = useState(501);
  const [checkout, setCheckout] = useState<Checkoutin>("double");
  const [players, setPlayers] = useState<NewMatchPlayer[]>(() =>
    defaultPlayers.map((name) => ({ name, handicap: null }))
  );

  // Create match
  const createMatchCallback = useAsyncCallback(
    async (controller) => {
      const allPlayers = await getPlayers(controller);
      if (allPlayers.tag !== "Value") return;

      const playersFiltered = players.flatMap((player) => {
        const trimmed = player.name.trim();
        return trimmed === ""
          ? []
          : [{ name: trimmed, handicap: player.handicap }];
      });

      let playersFilteredWithId: (NewMatchPlayer & { player_id: string })[] =
        [];
      for (const player of playersFiltered) {
        const playerId =
          allPlayers.value.find((p) => p.name === player.name)?.id ?? null;
        if (playerId === null) {
          alert(`Player "${player.name}" not found`);
          return;
        }
        playersFilteredWithId.push({
          ...player,
          player_id: playerId,
        });
      }
      if (playersFilteredWithId.length === 0) {
        alert("At least one player is required");
        return;
      }

      if (randomOrder) {
        playersFilteredWithId = playersFilteredWithId
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
      }

      const res = await createMatch(
        {
          host_player_id: session.id,
          data: {
            kind: "X01",
            value: {
              start_value: startingScore,
              in: null,
              out: checkout,
              sets: 1,
              legs: 1,
              set_mode: "FirstTo",
              leg_mode: "FirstTo",
              players: playersFilteredWithId.map((player) => ({
                handicap: player.handicap,
                rounds: [],
              })),
            },
          },
          players: playersFilteredWithId.map((player) => player.player_id),
        },
        controller
      );
      if (res.tag === "Value") navigate(`/matches/${res.value.id}`);
      if (res.tag === "Error") alert(res.message);
    },
    [session.id, players, randomOrder, startingScore, checkout]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(3px)",
        display: show ? "block" : "none",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "5%",
          left: "10%",
          width: "80%",
          height: "90%",
          backgroundColor: "white",
          borderRadius: 8,
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100%",
          }}
        >
          <div style={{ padding: 16 }}>
            <h2>New Match</h2>

            <label>
              <input
                type="checkbox"
                checked={randomOrder}
                onChange={() => {
                  setRandomOrder(!randomOrder);
                }}
              />
              <span>Random Order</span>
            </label>

            {[...players, null].map((player, idx) => (
              <div key={idx} style={{ display: "flex" }}>
                <input
                  style={{ width: 150 }}
                  type="text"
                  value={player?.name ?? ""}
                  onChange={(event) => {
                    if (player === null) {
                      setPlayers([
                        ...players,
                        { name: event.target.value, handicap: null },
                      ]);
                      return;
                    }

                    if (event.target.value.trim() === "") {
                      const newPlayers = [...players];
                      newPlayers.splice(idx, 1);
                      setPlayers(newPlayers);
                    } else {
                      setPlayers(
                        players.map((p, i) =>
                          i === idx
                            ? {
                                ...p,
                                name: event.target.value,
                              }
                            : p
                        )
                      );
                    }
                  }}
                />
                {player === null ? null : (
                  <input
                    style={{ width: 75, marginLeft: 16 }}
                    type="number"
                    placeholder="Handicap"
                    value={player?.handicap ?? ""}
                    onChange={(event) => {
                      let handicap: number | null = Number(event.target.value);
                      if (!Number.isInteger(handicap) || handicap <= 0) {
                        handicap = null;
                      }
                      setPlayers(
                        players.map((p, i) =>
                          i === idx
                            ? {
                                ...p,
                                handicap,
                              }
                            : p
                        )
                      );
                    }}
                  />
                )}

                <br />
              </div>
            ))}

            <label>Game Mode</label>
            <select>
              <option value="X01">X01</option>
            </select>

            <label>Starting Score</label>
            <select
              value={startingScore}
              onChange={(e) => {
                setStartingScore(Number(e.target.value));
              }}
            >
              <option value="101">101</option>
              <option value="201">201</option>
              <option value="301">301</option>
              <option value="401">401</option>
              <option value="501">501</option>
              <option value="601">601</option>
              <option value="701">701</option>
              <option value="801">801</option>
              <option value="901">901</option>
              <option value="1001">1001</option>
            </select>

            <label>Checkout</label>
            <select
              value={checkout ?? "straight"}
              onChange={(e) => {
                switch (e.target.value) {
                  case "straight":
                    setCheckout(null);
                    break;
                  case "single":
                  case "double":
                  case "triple":
                  case "master":
                    setCheckout(e.target.value);
                    break;
                }
              }}
            >
              <option value="straight">straight</option>
              <option value="single">single</option>
              <option value="double">double</option>
              <option value="triple">triple</option>
              <option value="master">master</option>
            </select>

            <br />
            <br />
          </div>
          <div
            style={{
              padding: 32,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button onClick={onClose}>Close</button>
            <button onClick={createMatchCallback}>Start</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMatchDialog;
