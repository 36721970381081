import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DartsInput } from "../components";
import {
  getSession,
  createTraining,
  displayHit,
  Score,
  count25Hits,
  count50Hits,
} from "../api";
import { useAsyncCallback } from "../hooks";

const ROUNDS = 20;

const TraingBull: React.FC<{}> = () => {
  const navigate = useNavigate();

  // State
  const session = getSession()!;
  const [hits, setHits] = useState<Score[]>(() => {
    const hits = localStorage.getItem("training_bull");
    if (hits) return JSON.parse(hits);
    return [];
  });

  const currentRound = Math.floor(Math.max(hits.length - 1, 0) / 3) + 1;
  const roundsFinished = Math.floor(hits.length / 3);
  const average25 =
    count25Hits(hits.slice(0, roundsFinished * 3)) /
    Math.max(1, roundsFinished * 3);
  const average50 =
    count50Hits(hits.slice(0, roundsFinished * 3)) /
    Math.max(1, roundsFinished * 3);
  const averageBoth = average25 + average50;
  const lastRoundHits: (Score | null)[] = [
    hits[(currentRound - 1) * 3 + 0] ?? null,
    hits[(currentRound - 1) * 3 + 1] ?? null,
    hits[(currentRound - 1) * 3 + 2] ?? null,
  ];

  const addScore = useCallback(
    (score: Score) => {
      if (hits.length === ROUNDS * 3) return;
      setHits((hits) => [...hits, score]);
    },
    [hits.length]
  );
  const removeLastScore = useCallback(() => {
    setHits((hits) => hits.slice(0, hits.length - 1));
  }, []);

  useEffect(() => {
    localStorage.setItem("training_bull", JSON.stringify(hits));
  }, [hits]);

  const save = useAsyncCallback(
    async (controller) => {
      const res = await createTraining(
        {
          player_id: session.id,
          data: {
            kind: "Bull",
            value: {
              rounds: ROUNDS,
              hits,
            },
          },
        },
        controller
      );
      if (res.tag === "Value") {
        localStorage.removeItem("training_bull");
        navigate("/");
      }
      if (res.tag === "Error") alert(res.message);
    },
    [session, hits, navigate]
  );

  let finishAndSave = null;
  if (hits.length === ROUNDS * 3) {
    finishAndSave = (
      <>
        <br />
        <button onClick={() => save()}>Finish and Save</button>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "0 25%",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: "0 40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
            }}
          >
            <span>
              <b>25:</b> {(100 * average25).toFixed(2)}%
            </span>
            <span>
              <b>50:</b> {(100 * average50).toFixed(2)}%
            </span>
            <span>
              <b>25/50:</b> {(100 * averageBoth).toFixed(2)}%
            </span>
          </div>
          {lastRoundHits.map((hit, idx) => (
            <div
              key={idx}
              style={{
                flex: "0 20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                fontSize: "2rem",
              }}
            >
              {hit !== null ? displayHit(hit, hit.base === 25) : "-"}
            </div>
          ))}
        </div>
        <div
          style={{
            flex: "0 75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3 style={{ opacity: 0.5 }}>Bull</h3>
          <span style={{ fontSize: "2rem" }}>
            <b>Round:</b> {currentRound}/{ROUNDS}
          </span>
          {finishAndSave}
        </div>
      </div>
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DartsInput onScore={addScore} onBack={removeLastScore} />
      </div>
    </div>
  );
};

export default TraingBull;
