import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { DartsInput } from "../components";
import {
  getSession,
  Score,
  X01PlayerData,
  X01PlayerDataRound,
  Match,
  displayScore,
  scoreValue,
  ResultOrPending,
  Player,
  getPlayers,
  finishMatchById,
  Checkoutin,
} from "../api";
import { useAsyncCallback, useAsyncFetch } from "../hooks";

function totalScore(scores: (Score | null)[]): number {
  return scores.reduce(
    (acc, score) => acc + (score === null ? 0 : scoreValue(score)),
    0
  );
}

function applyRound(
  scoreLeft: number,
  round: X01PlayerDataRound,
  checkout: Checkoutin
): number | null {
  if (round.scores.length === 0) return scoreLeft;
  const lastMultiplier = round.scores[round.scores.length - 1].multiplier;

  const roundScore = totalScore(round.scores);
  if (roundScore > scoreLeft) return null;

  switch (checkout) {
    case null:
      break;
    case "single":
      if (roundScore === scoreLeft && lastMultiplier !== "single") return null;
      break;
    case "double":
      if (roundScore === scoreLeft - 1) return null;
      if (roundScore === scoreLeft && lastMultiplier !== "double") return null;
      break;
    case "triple":
      if (roundScore === scoreLeft - 1 || roundScore === scoreLeft - 2)
        return null;
      if (roundScore === scoreLeft && lastMultiplier !== "triple") return null;
      break;
    case "master":
      if (roundScore === scoreLeft - 1) return null;
      if (
        roundScore === scoreLeft &&
        lastMultiplier !== "double" &&
        lastMultiplier !== "triple"
      )
        return null;
      break;
  }

  return scoreLeft - roundScore;
}

function scoreX01(
  match: Match,
  playerData: X01PlayerData
): { left: number; throws: number; average: number; last: number } {
  let start = match.data.value.start_value + (playerData.handicap ?? 0);
  let left = start;
  let throws = 0;
  let last = 0;

  for (const round of playerData.rounds) {
    const newLeft = applyRound(left, round, match.data.value.out) ?? left;
    last = left - newLeft;
    left = newLeft;
    throws += round.scores.length;
  }

  return {
    left,
    throws,
    average: ((start - left) / Math.max(throws, 1)) * 3,
    last,
  };
}

function matchIsFinished(match: Match): boolean {
  return match.data.value.players.some(
    (playerData) => scoreX01(match, playerData).left === 0
  );
}

function matchCurrentPlayerIdxAndRoundAndThrow(
  match: Match
): [number, number, number] {
  let left = match.data.value.players.map(
    (player) => match.data.value.start_value + (player.handicap ?? 0)
  );
  let roundIdx = 0;

  while (true) {
    for (let i = 0; i < match.data.value.players.length; i++) {
      const playerData = match.data.value.players[i];

      const round = playerData.rounds[roundIdx];
      if (round === undefined) return [i, roundIdx, 0];

      const newLeft = applyRound(left[i], round, match.data.value.out);
      if (newLeft !== null) {
        left[i] = newLeft;
        if (left[i] === 0 || round.scores.length < 3)
          return [i, roundIdx, round.scores.length];
      }
    }

    roundIdx++;
  }
}

function matchLastPlayerIdxAndRound(match: Match): [number, number] | null {
  let roundIdx = 0;

  while (true) {
    for (let i = 0; i < match.data.value.players.length; i++) {
      const playerData = match.data.value.players[i];

      const round = playerData.rounds[roundIdx];
      if (round === undefined) {
        if (roundIdx === 0 && i === 0) return null;
        return i > 0
          ? [i - 1, roundIdx]
          : [match.data.value.players.length - 1, roundIdx - 1];
      }
    }

    roundIdx++;
  }
}

function matchAddScore(match: Match, score: Score): [number, Match] {
  const [currentPlayerIdx, currentPlayerRound] =
    matchCurrentPlayerIdxAndRoundAndThrow(match);
  return [
    currentPlayerIdx,
    {
      ...match,
      data: {
        ...match.data,
        value: {
          ...match.data.value,
          players: match.data.value.players.map((playerData, idx) => {
            if (idx === currentPlayerIdx) {
              if (currentPlayerRound < playerData.rounds.length) {
                return {
                  ...playerData,
                  rounds: playerData.rounds.map((round, idx) => {
                    if (idx === currentPlayerRound) {
                      return {
                        ...round,
                        scores: [...round.scores, score],
                      };
                    }
                    return round;
                  }),
                };
              } else {
                return {
                  ...playerData,
                  rounds: [...playerData.rounds, { scores: [score] }],
                };
              }
            }
            return playerData;
          }),
        },
      },
    },
  ];
}

function matchRemoveLastScore(match: Match): Match {
  const last = matchLastPlayerIdxAndRound(match);
  if (last === null) return match;
  const [lastPlayerIdx, lastPlayerRound] = last;
  return {
    ...match,
    data: {
      ...match.data,
      value: {
        ...match.data.value,
        players: match.data.value.players.map((playerData, idx) => {
          if (idx === lastPlayerIdx) {
            return {
              ...playerData,
              rounds: playerData.rounds.flatMap((round, idx) => {
                if (idx === lastPlayerRound) {
                  if (round.scores.length === 1) return [];
                  return [
                    {
                      ...round,
                      scores: round.scores.slice(0, -1),
                    },
                  ];
                }
                return [round];
              }),
            };
          }
          return playerData;
        }),
      },
    },
  };
}

const PlayMatch: React.FC<{}> = () => {
  const params = useParams();
  const matchId = params.matchId!;
  const navigate = useNavigate();

  const isDev = process.env.NODE_ENV === "development";
  const wsUrl = isDev
    ? "ws://localhost:5050/api/matches"
    : `wss://${window.location.hostname}/api/matches`;
  const { sendJsonMessage, lastJsonMessage } = useWebSocket<Match>(
    `${wsUrl}/${matchId}/live`
  );

  // State
  const session = getSession()!;
  const [match, setMatch] = useState<Match | null>(null);
  const [players, setPlayers] = useState<ResultOrPending<Player[]>>({
    tag: "Pending",
  });

  const addScore = useCallback(
    (score: Score) => {
      if (match === null) return;
      if (match.finished_at !== null) return;
      if (matchIsFinished(match)) return;

      const [playerAddedIdx, updated] = matchAddScore(match, score);
      if (
        matchCurrentPlayerIdxAndRoundAndThrow(updated)[2] === 0 ||
        matchIsFinished(updated)
      ) {
        const lastScore = scoreX01(
          updated,
          updated.data.value.players[playerAddedIdx]
        ).last;
        new Audio(`/audio/caller/call${lastScore}.mp3`).play();
      }

      sendJsonMessage({
        data: updated.data,
      });
      setMatch(updated);
    },
    [match, sendJsonMessage]
  );
  const removeLastScore = useCallback(() => {
    if (match === null) return;
    if (match.finished_at !== null) return;
    const updated = matchRemoveLastScore(match);
    sendJsonMessage({
      data: updated.data,
    });
    setMatch(updated);
  }, [match, sendJsonMessage]);

  const save = useAsyncCallback(
    async (controller) => {
      if (match === null) return;
      const res = await finishMatchById(
        match.id,
        {
          data: match.data,
        },
        controller
      );
      if (res.tag === "Value") {
        navigate("/");
      }
      if (res.tag === "Error") alert(res.message);
    },
    [match, navigate]
  );

  // Load players
  useAsyncFetch(
    async (controller) => {
      const res = await getPlayers(controller);
      if (res.tag !== "Canceled") setPlayers(res);
    },
    [session.id]
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMatch(lastJsonMessage);
    }
  }, [lastJsonMessage]);

  if (players.tag === "Error") return <div>Error: {players.message}</div>;
  if (match === null || players.tag === "Pending") return <div></div>;

  const [currentPlayerIdx, currentPlayerRound] =
    matchCurrentPlayerIdxAndRoundAndThrow(match);
  const localPlayerData = match.data.value.players.map((playerData, idx) => {
    const current = currentPlayerIdx === idx;
    return {
      current,
      name:
        players.value.find((p) => p.id === match.players[idx])?.name ??
        "Unknown",
      score: scoreX01(match, playerData),
      displayRound: (() => {
        const round =
          playerData.rounds[
            current ? currentPlayerRound : playerData.rounds.length - 1
          ];
        if (round === undefined) return [null, null, null];
        return [
          round.scores[0] ?? null,
          round.scores[1] ?? null,
          round.scores[2] ?? null,
        ];
      })(),
    };
  });

  let finishAndSave = null;
  if (matchIsFinished(match) && match.finished_at === null) {
    finishAndSave = (
      <>
        <br />
        <button onClick={() => save()}>Finish and Save</button>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {match.data.value.players.map((playerData, idx) => (
          <div
            key={idx}
            style={{
              flex: "0 25%",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                fontSize: "2rem",
              }}
            >
              <span>
                {localPlayerData[idx].current ? (
                  <b>{localPlayerData[idx].name}</b>
                ) : (
                  <span>{localPlayerData[idx].name}</span>
                )}
                {playerData.handicap ? (
                  <span style={{ fontSize: "1rem" }}>
                    {" "}
                    (+{playerData.handicap})
                  </span>
                ) : null}
              </span>
              <span> {localPlayerData[idx].score.left}</span>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                }}
              >
                {localPlayerData[idx].displayRound.map((score, idx) => (
                  <div
                    key={idx}
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid lightgray",
                      fontSize: "1.75rem",
                    }}
                  >
                    {score ? displayScore(score) : "-"}
                  </div>
                ))}
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid lightgray",
                }}
              >
                {localPlayerData[idx].current
                  ? totalScore(localPlayerData[idx].displayRound)
                  : localPlayerData[idx].score.last}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
              }}
            >
              {localPlayerData[idx].score.average.toFixed(2)}
            </div>
          </div>
        ))}
        <div
          style={{
            flex: "0 25%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {finishAndSave}
        </div>
      </div>
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DartsInput onScore={addScore} onBack={removeLastScore} />
      </div>
    </div>
  );
};

export default PlayMatch;
