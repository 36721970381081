const baseUrl = "/api";

export interface Value<T> {
  tag: "Value";
  value: T;
}
export interface Error {
  tag: "Error";
  code: number;
  message: string;
}
export interface Canceled {
  tag: "Canceled";
}
export interface Pending {
  tag: "Pending";
}
export type Result<T> = Value<T> | Error;
export type ResultOrCanceled<T> = Result<T> | Canceled;
export type ResultOrPending<T> = Result<T> | Pending;

export interface CallOptions {
  url: string;
  method: string;
  controller: AbortController;
  data?: unknown;
}

export async function call<T>({
  url,
  method,
  controller,
  data,
}: CallOptions): Promise<ResultOrCanceled<T>> {
  // Options
  const options: RequestInit = {
    method,
    signal: controller.signal,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
      "x-access-token": localStorage.getItem("token") ?? "",
    }),
    body: JSON.stringify(data),
  };

  // Fetch
  let response;
  try {
    response = await fetch(baseUrl + url, options);
  } catch (e) {
    if (e instanceof DOMException) {
      return { tag: "Canceled" };
    }

    if (e instanceof TypeError && e.message === "Failed to fetch") {
      return {
        tag: "Error",
        code: -2,
        message: "Network error",
      };
    }

    return {
      tag: "Error",
      code: -1,
      message: (e as any)?.message ?? "An error occurred",
    };
  }

  // Get json
  let json;
  try {
    json = await response.json();
  } catch (e) {
    if (e instanceof DOMException) {
      return { tag: "Canceled" };
    }

    return {
      tag: "Error",
      code: -3,
      message: "Received invalid JSON",
    };
  }

  // Check status code
  if (!response.ok) {
    return {
      tag: "Error",
      code: response.status,
      message: json.message ?? "An error occurred",
    };
  }

  // Return json
  return {
    tag: "Value",
    value: json,
  };
}
