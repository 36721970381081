import React, { useRef, useEffect } from "react";
import { Score } from "../api";

export interface DartBoardProps {
  scores: Score[];
}

const DartBoard: React.FC<DartBoardProps> = ({ scores }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const ctx = canvasRef.current!.getContext("2d")!;
    ctx.clearRect(0, 0, 1000, 1000);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#000000";
    ctx.font = "30px sans-serif";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.beginPath();
    ctx.arc(500, 500, 400, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(500, 500, 380, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(500, 500, 250, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(500, 500, 230, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(500, 500, 35, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(500, 500, 15, 0, 2 * Math.PI);
    ctx.stroke();

    const fields = [
      20, 1, 18, 4, 13, 6, 10, 15, 2, 17, 3, 19, 7, 16, 8, 11, 14, 9, 12, 5,
    ];

    for (let i = 0; i < 20; i++) {
      const angle = ((i - 5) * Math.PI) / 10;
      const x = Math.cos(angle);
      const y = Math.sin(angle);

      const angle1 = ((i - 4.5) * Math.PI) / 10;
      const x1 = Math.cos(angle1);
      const y1 = Math.sin(angle1);

      ctx.beginPath();
      ctx.moveTo(500 + 35 * x1, 500 + 35 * y1);
      ctx.lineTo(500 + 400 * x1, 500 + 400 * y1);
      ctx.stroke();

      ctx.strokeText(fields[i].toString(), 500 + 430 * x, 500 + 430 * y);
    }

    const colorFromTarget = (target: Score) => {
      let hitRate =
        scores.filter(
          (score) =>
            score.base === target.base && score.multiplier === target.multiplier
        ).length / scores.length;
      return `rgba(0, 100, 0, ${Math.pow(hitRate, 0.5)})`;
    };

    for (let i = 0; i < 20; i++) {
      const angle0 = ((i - 5.5) * Math.PI) / 10;
      const angle1 = ((i - 4.5) * Math.PI) / 10;
      const x1 = Math.cos(angle1);
      const y1 = Math.sin(angle1);

      const fill = (outer: number, inner: number) => {
        ctx.beginPath();
        ctx.arc(500, 500, outer, angle0, angle1);
        ctx.lineTo(500 + inner * x1, 500 + inner * y1);
        ctx.arc(500, 500, inner, angle1, angle0, true);
        ctx.closePath();
        ctx.fill();
      };

      ctx.fillStyle = colorFromTarget({
        base: fields[i],
        multiplier: "single",
      });
      fill(380, 250);
      fill(230, 35);

      ctx.fillStyle = colorFromTarget({
        base: fields[i],
        multiplier: "double",
      });
      fill(400, 380);

      ctx.fillStyle = colorFromTarget({
        base: fields[i],
        multiplier: "triple",
      });
      fill(250, 230);
    }

    ctx.fillStyle = colorFromTarget({
      base: 25,
      multiplier: "single",
    });
    ctx.beginPath();
    ctx.arc(500, 500, 35, 0, 2 * Math.PI, false);
    ctx.arc(500, 500, 15, 0, 2 * Math.PI, true);
    ctx.fill();

    ctx.fillStyle = colorFromTarget({
      base: 25,
      multiplier: "double",
    });
    ctx.beginPath();
    ctx.arc(500, 500, 15, 0, 2 * Math.PI);
    ctx.fill();
  }, [scores]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: "100%",
        height: "100%",
      }}
      width={1000}
      height={1000}
    ></canvas>
  );
};

export default DartBoard;
