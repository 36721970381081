import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncFetch } from "../hooks";
import { DartBoard } from "../components";
import {
  ResultOrPending,
  Training,
  getTrainingById,
  getSession,
  Score,
  displayScore,
  TrainingDataScoring,
  TrainingDataCheckout,
  TrainingDataBull,
  trainingName,
  scoreValue,
  displayHit,
  countCheckoutHits,
  count25Hits,
  count50Hits,
} from "../api";

function totalScore(scores: Score[]): number {
  return scores.reduce((acc, score) => acc + scoreValue(score), 0);
}

const ViewTraining: React.FC<{}> = () => {
  const params = useParams();
  const trainingId = params.trainingId!;

  // State
  const session = getSession()!;
  const [training, setTraining] = useState<ResultOrPending<Training>>({
    tag: "Pending",
  });

  // Load trainings
  useAsyncFetch(
    async (controller) => {
      const res = await getTrainingById(trainingId, controller);
      if (res.tag !== "Canceled") setTraining(res);
    },
    [session.id]
  );

  if (training.tag === "Pending") return <div></div>;
  if (training.tag === "Error") return <div>Error: {training.message}</div>;

  let viewData;
  switch (training.value.data.kind) {
    case "Scoring":
      viewData = <ViewTrainingScoring data={training.value.data} />;
      break;
    case "Checkout":
      viewData = <ViewTrainingCheckout data={training.value.data} />;
      break;
    case "Bull":
      viewData = <ViewTrainingBull data={training.value.data} />;
      break;
  }

  return (
    <div className="container" style={{ paddingTop: 16, paddingBottom: 16 }}>
      <h3>Training {trainingName(training.value)}</h3>

      {viewData}
    </div>
  );
};

const ViewTrainingScoring: React.FC<{ data: TrainingDataScoring }> = ({
  data,
}) => {
  const rounds = data.value.rounds;
  const scores = data.value.scores;
  const average = totalScore(scores) / rounds;

  const hitRate20 =
    scores.filter((score) => score.base === 20).length / scores.length;
  const hitRate19 =
    scores.filter((score) => score.base === 19).length / scores.length;
  const hitRate20or19 = hitRate20 + hitRate19;

  const hitRate60 =
    scores.filter((score) => scoreValue(score) === 60).length / scores.length;
  const hitRate57 =
    scores.filter((score) => scoreValue(score) === 57).length / scores.length;
  const hitRate60or57 = hitRate60 + hitRate57;

  const roundScores = [];
  for (let i = 0; i < scores.length; i += 3) {
    roundScores.push(scores.slice(i, i + 3));
  }

  return (
    <>
      <h4>Average Score</h4>
      <p>{average.toFixed(2)}</p>

      <h4>Hit Rate</h4>
      <p>
        <span>
          <b>20</b>: {(100 * hitRate20).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>19</b>: {(100 * hitRate19).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>20/19</b>: {(100 * hitRate20or19).toFixed(2)}%
        </span>
        <br />
        <br />
        <span>
          <b>60</b>: {(100 * hitRate60).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>57</b>: {(100 * hitRate57).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>60/57</b>: {(100 * hitRate60or57).toFixed(2)}%
        </span>
      </p>

      <h4>Scores</h4>
      {roundScores.map((scores, idx) => (
        <div key={idx}>
          <span>
            <b>Round {idx + 1}</b>: {displayScore(scores[0])}{" "}
            {displayScore(scores[1])} {displayScore(scores[2])}
          </span>
        </div>
      ))}
      <br />

      <h4>Board</h4>
      <div
        style={{ position: "relative", width: "100%", paddingBottom: "100%" }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <DartBoard scores={scores} />
        </div>
      </div>
    </>
  );
};

const ViewTrainingCheckout: React.FC<{ data: TrainingDataCheckout }> = ({
  data,
}) => {
  const hits = data.value.hits;
  const targetMultiplier = data.value.targetMultiplier;
  const hitRate = countCheckoutHits(hits, targetMultiplier) / hits.length;

  const roundHits = [];
  for (let i = 0; i < hits.length; i += 3) {
    roundHits.push(hits.slice(i, i + 3));
  }

  return (
    <>
      <h4>Hit Rate</h4>
      <p>{(100 * hitRate).toFixed(2)}%</p>

      <h4>Scores</h4>
      {roundHits.map((hits, idx) => (
        <div key={idx}>
          <span>
            <b>
              {displayScore({ base: idx + 1, multiplier: targetMultiplier })}
            </b>
            :{" "}
            {displayHit(
              hits[0],
              hits[0].base === idx + 1 &&
                hits[0].multiplier === targetMultiplier
            )}{" "}
            {displayHit(
              hits[1],
              hits[1].base === idx + 1 &&
                hits[1].multiplier === targetMultiplier
            )}{" "}
            {displayHit(
              hits[2],
              hits[2].base === idx + 1 &&
                hits[2].multiplier === targetMultiplier
            )}
          </span>
        </div>
      ))}
    </>
  );
};

const ViewTrainingBull: React.FC<{ data: TrainingDataBull }> = ({ data }) => {
  const hits = data.value.hits;
  const hitRate25 = count25Hits(hits) / hits.length;
  const hitRate50 = count50Hits(hits) / hits.length;
  const hitRateBoth = hitRate25 + hitRate50;

  const roundHits = [];
  for (let i = 0; i < hits.length; i += 3) {
    roundHits.push(hits.slice(i, i + 3));
  }

  return (
    <>
      <h4>Hit Rate</h4>
      <p>
        <span>
          <b>25</b>: {(100 * hitRate25).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>50</b>: {(100 * hitRate50).toFixed(2)}%
        </span>
        <br />
        <span>
          <b>25/50</b>: {(100 * hitRateBoth).toFixed(2)}%
        </span>
      </p>

      <h4>Scores</h4>
      {roundHits.map((hits, idx) => (
        <div key={idx}>
          <span>
            <b>Round {idx + 1}</b>: {displayHit(hits[0], hits[0].base === 25)}{" "}
            {displayHit(hits[1], hits[1].base === 25)}{" "}
            {displayHit(hits[2], hits[2].base === 25)}
          </span>
        </div>
      ))}
      <br />

      <h4>Board</h4>
      <div
        style={{ position: "relative", width: "100%", paddingBottom: "100%" }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <DartBoard scores={hits} />
        </div>
      </div>
    </>
  );
};

export default ViewTraining;
