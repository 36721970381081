import { ReactElement } from "react";
import * as base from "./base";

export type { Result, ResultOrCanceled, ResultOrPending } from "./base";

export interface Player {
  id: string;
  name: string;
}

export interface CreatePlayer {
  name: string;
}

export interface Training {
  id: string;
  player_id: string;
  data: TrainingData;
  created_at: string;
}

export interface CreateTraining {
  player_id: string;
  data: TrainingData;
}

export interface Match {
  id: string;
  host_player_id: string;
  players: string[];
  data: MatchData;
  created_at: string;
  finished_at: string | null;
}

export interface CreateMatch {
  host_player_id: string;
  data: MatchData;
  players: string[];
}

export interface UpdateMatch {
  data: MatchData;
}

export interface FinishMatch {
  data: MatchData;
}

export async function getPlayers(
  controller: AbortController
): Promise<base.ResultOrCanceled<Player[]>> {
  return base.call({
    url: `/players`,
    method: "GET",
    controller,
  });
}

export async function createPlayer(
  create: CreatePlayer,
  controller: AbortController
): Promise<base.ResultOrCanceled<Player>> {
  return base.call({
    url: `/players`,
    method: "POST",
    data: create,
    controller,
  });
}

export async function getPlayerById(
  id: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Player>> {
  return base.call({
    url: `/players/${id}`,
    method: "GET",
    controller,
  });
}

export async function loginOrRegister(
  name: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Player>> {
  const res = await getPlayers(controller);
  if (res.tag === "Canceled" || res.tag === "Error") return res;

  let player = res.value.find((p) => p.name === name);
  if (!player) {
    const res = await createPlayer({ name }, controller);
    if (res.tag === "Canceled" || res.tag === "Error") return res;
    player = res.value;
  }

  localStorage.setItem("session_id", player.id);
  localStorage.setItem("session_name", player.name);

  return { tag: "Value", value: player };
}

export function logout() {
  localStorage.removeItem("session_id");
  localStorage.removeItem("session_name");
}

export async function getTrainings(
  player_id: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Training[]>> {
  return base.call({
    url: `/trainings?player_id=${player_id}`,
    method: "GET",
    controller,
  });
}

export async function createTraining(
  create: CreateTraining,
  controller: AbortController
): Promise<base.ResultOrCanceled<Training>> {
  return base.call({
    url: `/trainings`,
    method: "POST",
    data: create,
    controller,
  });
}

export async function getTrainingById(
  id: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Training>> {
  return base.call({
    url: `/trainings/${id}`,
    method: "GET",
    controller,
  });
}

export async function getMatches(
  player_id: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Match[]>> {
  return base.call({
    url: `/matches?player_id=${player_id}`,
    method: "GET",
    controller,
  });
}

export async function createMatch(
  create: CreateMatch,
  controller: AbortController
): Promise<base.ResultOrCanceled<Match>> {
  return base.call({
    url: `/matches`,
    method: "POST",
    data: create,
    controller,
  });
}

export async function getMatchById(
  id: string,
  controller: AbortController
): Promise<base.ResultOrCanceled<Match>> {
  return base.call({
    url: `/matches/${id}`,
    method: "GET",
    controller,
  });
}

export async function finishMatchById(
  id: string,
  finish: FinishMatch,
  controller: AbortController
): Promise<base.ResultOrCanceled<Match>> {
  return base.call({
    url: `/matches/${id}/finish`,
    method: "POST",
    data: finish,
    controller,
  });
}

export function getSession(): Player | null {
  const id = localStorage.getItem("session_id");
  if (!id) return null;

  const name = localStorage.getItem("session_name");
  if (!name) return null;

  return { id, name };
}

export type MatchData = MatchDataX01;

export interface MatchDataX01 {
  kind: "X01";
  value: {
    start_value: number;
    in: Checkoutin;
    out: Checkoutin;
    sets: 1;
    legs: 1;
    set_mode: "FirstTo" | "BestOf";
    leg_mode: "FirstTo" | "BestOf";
    players: X01PlayerData[];
  };
}

export interface X01PlayerData {
  handicap?: number | null;
  rounds: X01PlayerDataRound[];
}

export interface X01PlayerDataRound {
  scores: Score[];
}

export type Checkoutin = ScoreMultiplier | "master" | null;

export type TrainingData =
  | TrainingDataScoring
  | TrainingDataCheckout
  | TrainingDataBull;

export interface TrainingDataScoring {
  kind: "Scoring";
  value: {
    target: TrainingDataScoringTarget;
    rounds: number;
    scores: Score[];
  };
}

export type TrainingDataScoringTarget = "free" | "19" | "20";

export interface TrainingDataCheckout {
  kind: "Checkout";
  value: {
    targetMultiplier: ScoreMultiplier;
    rounds: number;
    hits: Score[];
  };
}

export interface TrainingDataBull {
  kind: "Bull";
  value: {
    rounds: number;
    hits: Score[];
  };
}

export interface Score {
  base: number;
  multiplier: ScoreMultiplier;
}

export type ScoreMultiplier = "single" | "double" | "triple";

export function displayScore(score: Score): string {
  if (score.multiplier === "single") return `${score.base}`;
  if (score.multiplier === "double") return `D${score.base}`;
  if (score.multiplier === "triple") return `T${score.base}`;
  return `${score.multiplier}x${score.base}`;
}

export function scoreValue(score: Score): number {
  switch (score.multiplier) {
    case "single":
      return score.base;
    case "double":
      return score.base * 2;
    case "triple":
      return score.base * 3;
  }
}

export function countCheckoutHits(
  scores: Score[],
  targetMultiplier: ScoreMultiplier
): number {
  return scores.reduce(
    (acc, score, idx) =>
      score.base === Math.floor(idx / 3) + 1 &&
      score.multiplier === targetMultiplier
        ? acc + 1
        : acc,
    0
  );
}

export function count25Hits(scores: Score[]): number {
  return scores.reduce(
    (acc, score) =>
      score.base === 25 && score.multiplier === "single" ? acc + 1 : acc,
    0
  );
}

export function count50Hits(scores: Score[]): number {
  return scores.reduce(
    (acc, score) =>
      score.base === 25 && score.multiplier === "double" ? acc + 1 : acc,
    0
  );
}

export function displayHit(score: Score, hit: boolean): ReactElement {
  const display = displayScore(score);
  return <span style={{ color: hit ? "green" : "red" }}>{display}</span>;
}

export function trainingName(training: Training): string {
  switch (training.data.kind) {
    case "Scoring":
      return `Scoring (${training.data.value.target})`;
    case "Checkout":
      return `Checkout (${training.data.value.targetMultiplier})`;
    case "Bull":
      return `Bull`;
  }
}

export function matchName(match: Match): string {
  switch (match.data.kind) {
    case "X01":
      return `X01 (${match.data.value.start_value})`;
  }
}
