import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DartsInput } from "../components";
import {
  getSession,
  Score,
  displayScore,
  createTraining,
  TrainingDataScoringTarget,
  scoreValue,
} from "../api";
import { useAsyncCallback } from "../hooks";

const ROUNDS = 20;

function totalScore(scores: Score[]): number {
  return scores.reduce((acc, score) => acc + scoreValue(score), 0);
}

const TraingScoring: React.FC<{ target: TrainingDataScoringTarget }> = ({
  target,
}) => {
  const navigate = useNavigate();

  // State
  const session = getSession()!;
  const [scores, setScores] = useState<Score[]>(() => {
    const scores = localStorage.getItem(`training_scoring_${target}`);
    if (scores) return JSON.parse(scores);
    return [];
  });

  const currentRound = Math.floor(Math.max(scores.length - 1, 0) / 3) + 1;
  const roundsFinished = Math.floor(scores.length / 3);
  const average =
    totalScore(scores.slice(0, roundsFinished * 3)) /
    Math.max(1, roundsFinished);
  const currentRoundScores: (Score | null)[] = [
    scores[(currentRound - 1) * 3 + 0] ?? null,
    scores[(currentRound - 1) * 3 + 1] ?? null,
    scores[(currentRound - 1) * 3 + 2] ?? null,
  ];

  const addScore = useCallback(
    (score: Score) => {
      if (scores.length === ROUNDS * 3) return;
      setScores((scores) => [...scores, score]);
    },
    [scores.length]
  );
  const removeLastScore = useCallback(() => {
    setScores((scores) => scores.slice(0, scores.length - 1));
  }, []);

  useEffect(() => {
    localStorage.setItem(`training_scoring_${target}`, JSON.stringify(scores));
  }, [scores, target]);

  const save = useAsyncCallback(
    async (controller) => {
      const res = await createTraining(
        {
          player_id: session.id,
          data: {
            kind: "Scoring",
            value: {
              target,
              rounds: ROUNDS,
              scores: scores,
            },
          },
        },
        controller
      );
      if (res.tag === "Value") {
        localStorage.removeItem(`training_scoring_${target}`);
        navigate("/");
      }
      if (res.tag === "Error") alert(res.message);
    },
    [session, scores, target, navigate]
  );

  let finishAndSave = null;
  if (scores.length === ROUNDS * 3) {
    finishAndSave = (
      <>
        <br />
        <button onClick={() => save()}>Finish and Save</button>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "0 25%",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: "0 40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
              fontSize: "2rem",
            }}
          >
            {average.toFixed(2)}
          </div>
          {currentRoundScores.map((score, idx) => (
            <div
              key={idx}
              style={{
                flex: "0 20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                fontSize: "2rem",
              }}
            >
              {score ? displayScore(score) : "-"}
            </div>
          ))}
        </div>
        <div
          style={{
            flex: "0 75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3 style={{ opacity: 0.5 }}>Scoring ({target})</h3>
          <span style={{ fontSize: "2rem" }}>
            <b>Round:</b> {currentRound}/{ROUNDS}
          </span>
          {finishAndSave}
        </div>
      </div>
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DartsInput onScore={addScore} onBack={removeLastScore} />
      </div>
    </div>
  );
};

export default TraingScoring;
