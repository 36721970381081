import { useEffect, useCallback, useRef } from "react";

export function useAsyncCallback<Args extends unknown[], T>(
  callback: (controller: AbortController, ...args: Args) => Promise<T>,
  deps: ReadonlyArray<unknown>
): (...args: Args) => Promise<T> {
  const controller = useRef(new AbortController());
  useEffect(() => () => controller.current.abort(), []);
  return useCallback((...args) => {
    controller.current.abort();
    controller.current = new AbortController();
    return callback(controller.current, ...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useAsyncFetch(
  effect: (controller: AbortController) => Promise<void>,
  deps?: ReadonlyArray<unknown>
): void {
  useEffect(() => {
    const controller = new AbortController();
    void effect(controller);
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
