import React, { useState } from "react";
import { useAsyncFetch } from "../hooks";
import { Link, useNavigate } from "react-router-dom";
import {
  ResultOrPending,
  Training,
  Match,
  getTrainings,
  getMatches,
  getSession,
  logout,
  trainingName,
  matchName,
} from "../api";
import { NewMatchDialog } from "../components";

const HomePage: React.FC<{}> = () => {
  const navigate = useNavigate();

  // State
  const session = getSession()!;
  const [showNewMatchDialog, setShowNewMatchDialog] = useState(false);
  const [trainings, setTrainings] = useState<ResultOrPending<Training[]>>({
    tag: "Pending",
  });
  const [matches, setMatches] = useState<ResultOrPending<Match[]>>({
    tag: "Pending",
  });

  // Load trainings
  useAsyncFetch(
    async (controller) => {
      const res = await getTrainings(session.id, controller);
      if (res.tag !== "Canceled") setTrainings(res);
    },
    [session.id]
  );

  // Load matches
  useAsyncFetch(
    async (controller) => {
      const res = await getMatches(session.id, controller);
      if (res.tag !== "Canceled") setMatches(res);
    },
    [session.id]
  );

  if (trainings.tag === "Pending" || matches.tag === "Pending") {
    return <div></div>;
  }
  if (trainings.tag === "Error") return <div>Error: {trainings.message}</div>;
  if (matches.tag === "Error") return <div>Error: {matches.message}</div>;

  return (
    <div className="container" style={{ paddingTop: 16, paddingBottom: 16 }}>
      <NewMatchDialog
        defaultPlayers={[session.name]}
        show={showNewMatchDialog}
        onClose={() => setShowNewMatchDialog(false)}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Darts</h3>
        <span
          onClick={() => {
            logout();
            window.location.reload();
          }}
          style={{
            cursor: "pointer",
            color: "#1EAEDB",
            textDecoration: "underline",
          }}
        >
          Logout
        </span>
      </div>
      <p>Hello {session.name}!</p>
      <h4>Match</h4>
      <button
        onClick={() => {
          setShowNewMatchDialog(true);
        }}
      >
        New Match
      </button>
      <br />
      <br />

      <h4>Training</h4>
      <div
        style={{
          display: "flex",
        }}
      >
        <span style={{ flex: "1", textAlign: "right" }}>Scoring:</span>
        <div
          style={{ flex: "3", display: "flex", justifyContent: "space-around" }}
        >
          <Link to={`/training-scoring`}>Free</Link>
          <Link to={`/training-scoring-19`}>19</Link>
          <Link to={`/training-scoring-20`}>20</Link>{" "}
        </div>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <span style={{ flex: "1", textAlign: "right" }}>Checkout:</span>
        <div
          style={{ flex: "3", display: "flex", justifyContent: "space-around" }}
        >
          <Link to={`/training-checkout-single`}>Single</Link>
          <Link to={`/training-checkout-double`}>Double</Link>
          <Link to={`/training-checkout-triple`}>Triple</Link>
        </div>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <span style={{ flex: "1", textAlign: "right" }}>Bull:</span>
        <div
          style={{ flex: "3", display: "flex", justifyContent: "space-around" }}
        >
          <Link to={`/training-bull`}>Bull</Link>
        </div>
      </div>
      <br />
      <br />
      <h4>Matches</h4>
      <table className="u-full-width">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Type</th>
            <th style={{ width: "65%" }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {matches.value.map((match) => (
            <tr
              key={match.id}
              onClick={() => navigate(`/matches/${match.id}`)}
              style={{ cursor: "pointer" }}
            >
              <td>
                {match.finished_at === null ? (
                  <b>{matchName(match)}</b>
                ) : (
                  <>{matchName(match)}</>
                )}
              </td>
              <td>
                {new Date(
                  match.finished_at || match.created_at
                ).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      <h4>Trainings</h4>
      <table className="u-full-width">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>Type</th>
            <th style={{ width: "65%" }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {trainings.value.map((training) => (
            <tr
              key={training.id}
              onClick={() => navigate(`/trainings/${training.id}`)}
              style={{ cursor: "pointer" }}
            >
              <td>{trainingName(training)}</td>
              <td>{new Date(training.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HomePage;
