import React from "react";
import { Outlet } from "react-router-dom";
import { getSession, loginOrRegister } from "./api";
import { useAsyncCallback } from "./hooks";

const Scaffold: React.FC<{}> = () => {
  const session = getSession();

  if (session === null) return <Login />;

  return (
    <>
      <Outlet />
    </>
  );
};

const Login: React.FC<{}> = () => {
  const [name, setName] = React.useState("");
  const login = useAsyncCallback(async (controller, name: string) => {
    const res = await loginOrRegister(name, controller);
    if (res.tag === "Value") window.location.reload();
  }, []);

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
        />
        <button onClick={() => login(name)}>Login</button>
      </div>
    </>
  );
};

export default Scaffold;
