import React, { useState } from "react";
import { Property } from "csstype";
import { Score, ScoreMultiplier } from "../api";

export interface DartsInputProps {
  onScore: (score: Score) => void;
  onBack: () => void;
}

const DartsInput: React.FC<DartsInputProps> = ({ onScore, onBack }) => {
  const [multiplierSelected, setMultiplierSelected] =
    useState<ScoreMultiplier>("single");

  const enterScore = (base: number) => {
    window?.navigator?.vibrate?.(30);
    onScore({ base, multiplier: multiplierSelected });
    setMultiplierSelected("single");
  };

  return (
    <>
      <div
        style={{
          flex: "0 25%",
          display: "flex",
          fontSize: "2rem",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((base, idx) => (
          <Input key={idx} onClick={() => enterScore(base)} cursor="pointer">
            {base}
          </Input>
        ))}
      </div>
      <div
        style={{
          flex: "0 25%",
          display: "flex",
          fontSize: "2rem",
        }}
      >
        {[8, 9, 10, 11, 12, 13, 14].map((base, idx) => (
          <Input key={idx} onClick={() => enterScore(base)} cursor="pointer">
            {base}
          </Input>
        ))}
      </div>
      <div
        style={{
          flex: "0 25%",
          display: "flex",
          fontSize: "2rem",
        }}
      >
        {[15, 16, 17, 18, 19, 20].map((base, idx) => (
          <Input key={idx} onClick={() => enterScore(base)} cursor="pointer">
            {base}
          </Input>
        ))}
        <Input
          onClick={() => {
            if (multiplierSelected !== "triple") {
              enterScore(25);
            }
          }}
          cursor={multiplierSelected !== "triple" ? "pointer" : "default"}
          color={multiplierSelected !== "triple" ? undefined : "lightgray"}
        >
          25
        </Input>
      </div>
      <div
        style={{
          flex: "0 25%",
          display: "flex",
          fontSize: "2rem",
        }}
      >
        <Input
          onClick={() => {
            if (multiplierSelected === "single") {
              enterScore(0);
            }
          }}
          cursor={multiplierSelected === "single" ? "pointer" : "default"}
          color={multiplierSelected === "single" ? undefined : "lightgray"}
        >
          0
        </Input>
        <Input
          onClick={() => {
            if (multiplierSelected === "double") {
              setMultiplierSelected("single");
            } else {
              setMultiplierSelected("double");
            }
          }}
          cursor="pointer"
          flexSize={2}
          backgroundColor={
            multiplierSelected === "double" ? "#15a715" : "#90ee90"
          }
        >
          Double
        </Input>
        <Input
          onClick={() => {
            if (multiplierSelected === "triple") {
              setMultiplierSelected("single");
            } else {
              setMultiplierSelected("triple");
            }
          }}
          cursor="pointer"
          flexSize={2}
          backgroundColor={
            multiplierSelected === "triple" ? "#c02727" : "#ee9090"
          }
        >
          Triple
        </Input>
        <Input
          onClick={onBack}
          cursor="pointer"
          flexSize={2}
          backgroundColor="#e9bd69"
        >
          Back
        </Input>
      </div>
    </>
  );
};

interface InputProps {
  onClick: () => void;
  cursor: Property.Cursor;
  color?: Property.Color;
  backgroundColor?: Property.BackgroundColor;
  flexSize?: number;
  children: React.ReactNode;
}

const Input: React.FC<InputProps> = ({
  onClick,
  cursor,
  color,
  backgroundColor,
  children,
  flexSize,
}) => {
  return (
    <div
      style={{
        flex: flexSize ? `${flexSize} ${flexSize} 0` : "1 1 0",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid black",
          userSelect: "none",
          margin: "2px",
          cursor,
          color,
          backgroundColor,
        }}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};

export default DartsInput;
