import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DartsInput } from "../components";
import {
  getSession,
  createTraining,
  ScoreMultiplier,
  displayHit,
  displayScore,
  Score,
  countCheckoutHits,
} from "../api";
import { useAsyncCallback } from "../hooks";

const TraingCheckout: React.FC<{ targetMultiplier: ScoreMultiplier }> = ({
  targetMultiplier,
}) => {
  const navigate = useNavigate();

  // State
  const session = getSession()!;
  const [hits, setHits] = useState<Score[]>(() => {
    const hits = localStorage.getItem(`training_checkout_${targetMultiplier}`);
    if (hits) return JSON.parse(hits);
    return [];
  });

  const lastTarget = Math.floor(Math.max(hits.length - 1, 0) / 3) + 1;
  const nextTarget = Math.min(Math.floor(hits.length / 3) + 1, 20);
  const targetsFinished = Math.floor(hits.length / 3);
  const average =
    countCheckoutHits(hits.slice(0, targetsFinished * 3), targetMultiplier) /
    Math.max(1, targetsFinished * 3);
  const lastRoundHits: (Score | null)[] = [
    hits[(lastTarget - 1) * 3 + 0] ?? null,
    hits[(lastTarget - 1) * 3 + 1] ?? null,
    hits[(lastTarget - 1) * 3 + 2] ?? null,
  ];

  const addScore = useCallback(
    (score: Score) => {
      if (hits.length === 60) return;
      setHits((hits) => [...hits, score]);
    },
    [hits.length]
  );
  const removeLastScore = useCallback(() => {
    setHits((hits) => hits.slice(0, hits.length - 1));
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `training_checkout_${targetMultiplier}`,
      JSON.stringify(hits)
    );
  }, [hits, targetMultiplier]);

  const save = useAsyncCallback(
    async (controller) => {
      const res = await createTraining(
        {
          player_id: session.id,
          data: {
            kind: "Checkout",
            value: {
              targetMultiplier,
              rounds: 1,
              hits,
            },
          },
        },
        controller
      );
      if (res.tag === "Value") {
        localStorage.removeItem(`training_checkout_${targetMultiplier}`);
        navigate("/");
      }
      if (res.tag === "Error") alert(res.message);
    },
    [session, hits, targetMultiplier, navigate]
  );

  let finishAndSave = null;
  if (hits.length === 60) {
    finishAndSave = (
      <>
        <br />
        <button onClick={() => save()}>Finish and Save</button>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "0 25%",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: "0 40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
              fontSize: "2rem",
            }}
          >
            {(100 * average).toFixed(2)}%
          </div>
          {lastRoundHits.map((hit, idx) => (
            <div
              key={idx}
              style={{
                flex: "0 20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                fontSize: "2rem",
              }}
            >
              {hit !== null
                ? displayHit(
                    hit,
                    hit.base === lastTarget &&
                      hit.multiplier === targetMultiplier
                  )
                : "-"}
            </div>
          ))}
        </div>
        <div
          style={{
            flex: "0 75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3 style={{ opacity: 0.5 }}>Checkout ({targetMultiplier})</h3>
          <span style={{ fontSize: "2rem" }}>
            <b>Target:</b>{" "}
            {displayScore({ base: nextTarget, multiplier: targetMultiplier })}
          </span>
          {finishAndSave}
        </div>
      </div>
      <div
        style={{
          flex: "0 50%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DartsInput onScore={addScore} onBack={removeLastScore} />
      </div>
    </div>
  );
};

export default TraingCheckout;
