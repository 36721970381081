import { BrowserRouter, Routes, Route } from "react-router-dom";

import Scaffold from "./Scaffold";
import {
  HomePage,
  NotFoundPage,
  ViewTraining,
  TrainingScoring,
  TrainingCheckout,
  TrainingBull,
  PlayMatch,
} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Scaffold />}>
          <Route index element={<HomePage />} />
          <Route
            path="training-scoring"
            element={<TrainingScoring target="free" />}
          />
          <Route
            path="training-scoring-19"
            element={<TrainingScoring target="19" />}
          />
          <Route
            path="training-scoring-20"
            element={<TrainingScoring target="20" />}
          />
          <Route
            path="training-checkout-single"
            element={<TrainingCheckout targetMultiplier="single" />}
          />
          <Route
            path="training-checkout-double"
            element={<TrainingCheckout targetMultiplier="double" />}
          />{" "}
          <Route
            path="training-checkout-triple"
            element={<TrainingCheckout targetMultiplier="triple" />}
          />
          <Route path="training-bull" element={<TrainingBull />} />
          <Route path="trainings/:trainingId" element={<ViewTraining />} />
          <Route path="matches/:matchId" element={<PlayMatch />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
